import { Link } from "react-router-dom";
import styleMain from "./main.module.scss";
import { useEffect, useState } from "react";
import { MEXC_URL } from "../../utils/const";
import axios from "axios";

function Main() {
    const [tradesCount, setTradesCount] = useState(0)
    const [tradesProfit, setTradesProfit] = useState(0)

    const getTokenFromLocalStorage = () => {
        return localStorage.getItem("accessToken");
    };

    useEffect(() => {
        axios.get(`${MEXC_URL}/api/base-info`, {
            headers: {
                'Accept': 'application/json',
                Authorization: `bearer ${getTokenFromLocalStorage()}`,
            },
        })
            .then(function (response) {
                setTradesCount(response.data.trades_count)
                setTradesProfit(response.data.trades_profit)
            })
            .catch(function (error) { })
            .finally(function () { });
    }, [])

    return (
        <div className={styleMain.container}>
            <div className={styleMain.header__ball__left}></div>
            <header>
                <img src="/img/logo.png" alt="Логотип Athkeeper" />
                <nav>
                    {/* <div className="">RU</div> */}
                    <Link to="/main/sign-in">
                        <button>войти</button>
                    </Link>
                    <Link to="/main/sign-up">
                        <button>зарегистрироваться</button>
                    </Link>
                </nav>
            </header>

            <main>
                <div className={styleMain.main__items}>
                    <h1>ЛУЧШИЙ БОТ ДЛЯ СПОТОВОЙ ТОРГОВЛИ</h1>
                    <h2>Откройте для себя способ заработка с минимальными рисками, используя нашего торгового бота</h2>
                    <Link to="/main/sign-up">
                        <button>зарегистрироваться</button>
                    </Link>
                </div>
                <div className={styleMain.main__items}>
                    <div className={styleMain.main__robot}>
                        {/* <img src="/img/main_robot.png" alt="robot" /> */}
                    </div>
                    <div className={styleMain.allinfo}>
                        <div className={styleMain.allinfo__items}>
                            <div>
                                +{Math.round(tradesProfit)}
                                <span> USD</span>
                            </div>
                            <p>Общий профит сайта</p>
                        </div>
                        <div className={styleMain.vectors}></div>
                        <div className={styleMain.allinfo__items}>
                            <div>
                                +{tradesCount}
                                <span> K</span>
                            </div>
                            <p>Всего сделок</p>
                        </div>
                        <div className={styleMain.vectors}></div>
                        <div className={styleMain.allinfo__items}>
                            <div>
                                +16,7
                                <span> %</span>
                            </div>
                            <p>Текущий доход за месяц</p>
                        </div>
                    </div>
                </div>
            </main>
            <div className={styleMain.main__ball__rigth}></div>

            <div className={styleMain.about}>
                <h2>о платформе</h2>
                <p>Добро пожаловать в мир автоматизированной торговли с нашим криптоторговым ботом!
                <br />
                Мы создали его для того, чтобы сделать процесс покупки и продажи на спотовом рынке простым и эффективным.
                <br /><br />
                Представьте себе, что вы можете заполучить прибыль на каждом пике, не волнуясь о резких колебаниях цен. Наш бот способен фиксировать прибыль при каждом АТН, минимизируя риски, когда рынок начинает падать. Он работает на основе строгих алгоритмов, которые принимают обдуманные решения, избавляя вас от импульсивных сделок, вызванных эмоциями.
                <br /><br />
                С нами вы станете экспертом в скальпировании, а не просто наблюдателем за рынком. Торговля станет для вас не только прибыльным занятием, но и увлекательным процессом, где каждый ваш шаг будет подкреплен взвешенными решениями.
                <br /><br />
                Присоединяйтесь к нам и позвольте нашему боту стать вашим надежным спутником в мире криптовалют!
                <br /><br />
                Be FOMOless</p>
            </div>

            <div className={styleMain.safely__ball__left}></div>
            <div className={styleMain.safely}>
                <div className={styleMain.safely__items}>
                    <div className={styleMain.safely__items__row}>
                        <h2>безопасно</h2>
                        <div>
                            <h4>Ваши средства в безопасности</h4>
                            <p>Ваши средства остаются на вашем аккаунте на криптобирже.</p>
                        </div>
                    </div>
                    <div className={styleMain.safely__items__row}>
                        <h2>удобно</h2>
                        <div>
                            <h4><span>Нужно только</span> API-ключи</h4>
                            <p>Вы создаете API-ключи на своей бирже и предоставляете их нам.</p>
                        </div>
                    </div>
                    <div className={styleMain.safely__items__row}>
                        <h2>эффективно</h2>
                        <div>
                            <h4>и <span>желание</span> заработать</h4>
                            <p>Наш бот торгует на вашем аккаунте на бирже, но не имеет доступа к вашим средствам, кроме как для торговли.</p>
                        </div>
                    </div>

                </div>
                <div className={styleMain.safely__text}>
                    <p>ATHKEEPER не имеет и никогда не будет иметь доступа к вашим средствам.</p>
                    <p>Наша платформа исполняет сделки, сохраняя всю информацию конфиденциальной.</p>
                </div>
                <Link to="/main/sign-in">
                    <button>Торговать</button>
                </Link>
            </div>
            <div className={styleMain.safely__ball__rigth}></div>

            <div className={styleMain.advantages}>
                <h2>Преимущества</h2>
                <div className={styleMain.advantages__rows}>
                    <div>
                        <img src="/img/profit.png" alt="advantages" />
                        <p>Доходность <br /> от <span>4% /мес.</span> в USD</p>
                    </div>
                    <div>
                        <img src="/img/spot.png" alt="advantages" />
                        <p>Исключительно <span>спотовая</span> торговля BTC, ETH, KAS</p>
                    </div>
                    <div>
                        <img src="/img/fixation.png" alt="advantages" />
                        <p>Фиксация доходов в <span>стейблкоинах</span> USDT, USDC</p>
                    </div>
                </div>
            </div>

            <div className={styleMain.ready__ball__left}></div>
            <div className={styleMain.ready}>
                <div className={styleMain.ready__items}>
                    <h2>Остались вопросы?</h2>
                    <p>Присоединяйтесь к нашему чату, мы с радостью на них ответим!</p>
                    <button><a href="https://t.me/athkeeper" target="_blank">Присоединиться</a></button>
                    <span>Давайте упростим процесс:</span>
                </div>
                <div className={styleMain.ready__items}>
                    <h3>Зарегистрируйтесь</h3>
                    <h3>Подключите вашу биржу</h3>
                    <h3>начните торговать</h3>
                    <Link to="/main/sign-in">
                        <button>Торговать</button>
                    </Link>
                </div>
            </div>
            <div className={styleMain.ready__ball__rigth}></div>

            <footer>
                <img src="/img/logo.png" alt="logo" className={styleMain.footer__img} />
                <nav>
                    <div>
                        <span>Contact us</span>
                        <a href="https://t.me/athkeeper">t.me/athkeeper</a>
                    </div>
                </nav>
                <button>
                    <img src="/img/scroll__up.png" alt="scroll up" />
                </button>
            </footer>
        </div>
    )
}

export default Main